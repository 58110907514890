import React, {useEffect, useState} from 'react';
import {Security} from "../../util/security/Security";
import {IMember} from "../../api/model/IMember";
import {ProfileApi} from "../../api/ProfileApi";
import {MemberApi} from "../../api/MemberApi";
import {Include} from "../../api/model/Include";
import {Biography, Card, CardPhoto, CardTitle, CenteredContent, Info, Name, Relatives} from "./styles";
import {Section} from "../Section";
import {Search} from "../Search";
import {SelectMember} from "../SelectMember";

export interface IProfileDetail {
    isLoading: boolean
    photo: string
    name: string
    birthday: string
    age: string,
    member?: IMember,
    parents: Array<IMember>,
    children: Array<IMember>
}

export const Profile: React.FC<{ memberId: number }> = ({memberId}) => {
    const [isLoading, setIsLoading] = useState(true);

    const [member, setMember] = useState<IMember>();
    const [parents, setParents] = useState<Array<IMember>>([]);
    const [children, setChildren] = useState<Array<IMember>>([]);

    useEffect(() => {
        const memberApi = new MemberApi()
        // Get profile member
        memberApi.getMember(Number(memberId), [Include.PROFILES])
            .then((data) => {
                setMember(data);
                const relativeIds = data.parents.concat(data.children)
                if (relativeIds.length > 0) {
                    // Get relatives
                    debugger
                    memberApi.getMembers(relativeIds)
                        .then((relatives) => {
                            debugger
                            setParents(relatives.filter(it => data.parents.includes(it.id!!)))
                            setChildren(relatives.filter(it => data.children.includes(it.id!!)))
                            setIsLoading(false)
                        });
                } else {
                    setIsLoading(false)
                }
            });
    }, [])

    return isLoading
        ? showLoadingProfile()
        : showProfile(member!!, parents, children)
};

function showLoadingProfile() {
    return <ProfileWrapper isLoading={true}
                           photo={""}
                           name={""}
                           birthday={""}
                           age={""}
                           parents={[]}
                           children={[]}
    />
}

function showProfile(member: IMember, parents: Array<IMember>, children: Array<IMember>) {
    let thumbnail = member.thumbnail ? `/files/${member.thumbnail}` : process.env.PUBLIC_URL + 'images/no-photo.png'
    let fullName = `${member.name} ${member.surname}`;
    let ageToShow = ""
    let birthdayToShow = "No date provided :("

    if (member.birthday) {
        const date = new Date(member.birthday * 1000)
        const age = member.deathDate
            ? calculateYears(date, new Date(member.deathDate * 1000))
            : calculateYears(date, new Date())

        ageToShow = `Возраст: ${age}`
        birthdayToShow = date.toLocaleDateString(window.navigator.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }

    return <ProfileWrapper isLoading={false}
                           photo={thumbnail}
                           name={fullName}
                           birthday={birthdayToShow}
                           age={ageToShow}
                           member={member}
                           parents={parents}
                           children={children}
    />
}

const ProfileWrapper: React.FC<IProfileDetail> = (profileDetail) => {
    if (profileDetail.isLoading) {
        return <ProfileSection {...profileDetail}/>
    }

    return (
        <>
            <ProfileSection {...profileDetail}/>
            <RelativesSection {...profileDetail}/>
            <BiographySection {...profileDetail}/>
        </>
    );
}

const ProfileSection: React.FC<IProfileDetail> = (profileDetail) => {
    const isAdmin = Security.isAdmin()
    return <Section
        header={"Profile"}
        editable={isAdmin}
        body={
            <CenteredContent>
                <Card>
                    <CardPhoto photo={profileDetail.photo} isLoading={profileDetail.isLoading}></CardPhoto>
                    <CardTitle>
                        <Name>{profileDetail.name}</Name>
                        <Info>{profileDetail.birthday}</Info>
                        <Info>{profileDetail.age}</Info>
                    </CardTitle>
                </Card>
            </CenteredContent>
        }></Section>
}

const RelativesSection: React.FC<IProfileDetail> = (profileDetail) => {
    const memberApi = new MemberApi()
    const isAdmin = Security.isAdmin()
    const member = profileDetail.member

    const [editorMode, setEditorMode] = useState<boolean>(false);
    const [parents, setParents] = useState(profileDetail.parents);
    const [children, setChildren] = useState(profileDetail.children);

    function editRelatives() {
        setEditorMode(true)
    }

    function saveRelatives() {
        setEditorMode(false)
        if (member) {
            member.parents = parents ? parents.map(parent => parent.id!!) : []
            member.children = children ? children.map(child => child.id!!) : []
            memberApi.updateMember(member)
                .then((data) => {
                    console.info(`Member saved: ${data}`)
                });
        }
    }

    function cancelRelativesEdit() {
        setEditorMode(false)
    }

    function onSearch(e: React.ChangeEvent<HTMLInputElement>) {
        let searchingFor = e.target.value
        if(searchingFor.length > 2) {
            console.log(`Searching for: ${searchingFor}`)
            memberApi.findMembers(searchingFor)
                .then((data) => {
                    console.log(`Searching found: `)
                    data.forEach(member => console.log(` - ${member.name} ${member.surname}`))
                });
        }
    }

    return <Section
        header={"Relatives"}
        editable={isAdmin}
        onEdit={editRelatives}
        onSave={saveRelatives}
        onCancel={cancelRelativesEdit}
        body={
            <CenteredContent>
                <Relatives>
                    Parents
                    <ul>
                        {
                            parents.map(parent =>
                                <li>
                                    <img
                                        src={parent.thumbnail ? `/files/${parent.thumbnail}` : process.env.PUBLIC_URL + 'images/no-photo.png'}
                                        alt={parent.name}
                                    />
                                    <p>
                                        <b>{parent.name} {parent.surname} {parent.maidenName ? parent.maidenName : ""}</b>
                                    </p>
                                </li>
                            )
                        }
                        {
                            editorMode
                                ? <SelectMember/>
                                : null
                        }
                    </ul>
                </Relatives>
                <Relatives>
                    Children
                    <ul>
                        {
                            children.map(child =>
                                <li>
                                    <img
                                        src={child.thumbnail ? `/files/${child.thumbnail}` : process.env.PUBLIC_URL + 'images/no-photo.png'}
                                        alt={child.name}
                                    />
                                    <p>
                                        <b>{child.name} {child.surname} {child.maidenName ? child.maidenName : ""}</b>
                                    </p>
                                </li>
                            )
                        }
                    </ul>
                </Relatives>
            </CenteredContent>
        }></Section>
}

const BiographySection: React.FC<IProfileDetail> = (profileDetail) => {
    const isAdmin = Security.isAdmin()
    const profile = profileDetail.member?.profile

    const [editorMode, setEditorMode] = useState<boolean>(false);
    const [biography, setBiography] = useState(profile?.biography);

    function editBiography() {
        setEditorMode(true)
    }

    function saveBiography() {
        setEditorMode(false)
        if (profile && biography) {
            const profileApi = new ProfileApi()
            profile.biography = biography
            profileApi.updateProfile(profile)
                .then((data) => {
                    console.info(`Profile saved: ${data}`)
                });
        }
    }

    function cancelBiographyEdit() {
        setEditorMode(false)
        if (profile) {
            setBiography(profile.biography)
        }
    }

    return <Section
        header={"Biography"}
        editable={isAdmin}
        onEdit={editBiography}
        onSave={saveBiography}
        onCancel={cancelBiographyEdit}
        body={
            <CenteredContent>
                <Biography contentEditable={editorMode}
                           onInput={e => e.currentTarget.textContent ? setBiography(e.currentTarget.textContent) : null}>
                    {profile?.biography}
                </Biography>
            </CenteredContent>
        }></Section>
}

function calculateYears(fromDate: Date, tillDate: Date) {
    let yearDifference = tillDate.getFullYear() - fromDate.getFullYear();
    let oneOrZero = (tillDate.getMonth() < fromDate.getMonth()) ||
    (tillDate.getMonth() === fromDate.getMonth() && tillDate.getDate() < fromDate.getDate()) ? 1 : 0;
    return yearDifference - oneOrZero;
}
