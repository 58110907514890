import React, {useState} from 'react';
import {Select} from 'antd';
import {MemberApi} from "../../api/MemberApi";
import {DefaultOptionType} from "rc-select/lib/Select";

export const SelectMember: React.FC = () => {
    const memberApi = new MemberApi()
    const [options, setOptions] = useState<DefaultOptionType[]>([]);

    function onSearch(query: string) {
        if (query.length > 2 || options.length === 0) {
            memberApi.findMembers(query)
                .then((data) => {
                    data.forEach(member => console.log(` - ${member.name} ${member.surname}`))
                    let suggestedOptions = data.map(member => {
                        return {
                            value: member.id,
                            label: `${member.name} ${member.surname}`
                        }
                    })
                    setOptions(suggestedOptions);
                });
        }
    }

    return <Select
        showSearch
        style={{width: 200}}
        placeholder="Search to Select"
        optionFilterProp="children"
        onSearch={onSearch}
        options={options}
    />
};
