import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ReactDomServer from 'react-dom/server'
import {OrgChart} from 'd3-org-chart';
import {ILeaf} from "../../api/model/ILeaf";
import {FamilyTreeApi} from "../../api/FamilyTreeApi";
import {IFamilyTreeApi} from "../../api/IFamilyTreeApi";
import {Include} from "../../api/model/Include";
import {HierarchyNode} from "d3";
import {Canvas, Leaf, Member, MemberInfo, MemberPhoto} from "./styles";
import {IMember} from "../../api/model/IMember";

export const FamilyTree = () => {
    const [data, setData] = useState<Array<ILeaf>>();

    useEffect(() => {
        const familyTreeApi: IFamilyTreeApi = new FamilyTreeApi()
        familyTreeApi.getFamilyTree(1, [Include.FAMILY_TREES, Include.LEAVES, Include.MEMBERS])
            .then((data) => {
                setData(data.leaves);
            });
    }, [true]);

    return (
        <Canvas>
            <OrgChartComponent data={data}/>
        </Canvas>
    );
};

const OrgChartComponent = (props: any, ref: any) => {
    const d3Container = useRef(null);
    let chart: OrgChart<ILeaf>;
    let height = getWindowDimensions().height

    // We need to manipulate DOM
    useLayoutEffect(() => {
        if (props.data && d3Container.current) {
            if (!chart) {
                chart = new OrgChart<ILeaf>();
            }
            chart.container(d3Container.current)
                .svgHeight(height)
                .data(props.data)
                .nodeId((leaf) => leaf.id)
                .parentNodeId((leaf) => extractParentNodeId(leaf))
                .nodeWidth((leaf) => 200)
                .nodeHeight((leaf) => 140)
                .onNodeClick((nodeId) => {
                    // TODO: Move note to the screen center
                    // chart.setCentered(nodeId.path()).render()
                })
                .nodeContent(
                    function (node, i, nodes, state) {
                        const leaf = node.data
                        return ReactDomServer.renderToStaticMarkup(
                            <Node id={leaf.id} name={leaf.name} members={leaf.members} trunks={[]} branches={[]}/>
                        )
                    })
                .compact(false)
                .render()
                .expandAll();
        }
    }, [props.data, d3Container.current]);

    return (
        <div>
            <div ref={d3Container}/>
        </div>
    );
};

function extractParentNodeId(leaf: HierarchyNode<ILeaf> | ILeaf) {
    const leafNode = leaf as ILeaf
    if (leafNode.trunks.length === 1) {
        return leafNode.trunks[0]
    } else if (leafNode.trunks.length > 1) {
        console.warn(`More than one parent leaf detected for leafId=${leafNode.id} trunks=${leafNode.trunks}. The first one will be used`)
        return leafNode.trunks[0]
    }
    return undefined
}

const Node: React.FC<ILeaf> = ({
                                   id,
                                   members
                               }) => {
    return (
        <Leaf>
            <>
                {
                    members.map(member => {
                        return showMember(member)
                    })
                }
            </>
        </Leaf>);
}

function showMember(member: IMember) {
    let thumbnail = process.env.PUBLIC_URL + 'images/no-photo.png';
    let nameToDisplay = "";

    if (member.thumbnail) {
        thumbnail = `/files/${member.thumbnail}`;
    }
    nameToDisplay = `${member.name} ${member.surname}`
    if (member.maidenName) {
        nameToDisplay += ` (${member.maidenName})`
    }


    return <Member>
        <MemberPhoto photo={thumbnail}/>
        <MemberInfo>
            {nameToDisplay}
        </MemberInfo>
    </Member>
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}